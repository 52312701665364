import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const loginInfoStore = defineStore("loginInfo", () => {
    const loginInfo = ref({});

    function setLoginInfo(data) {
      loginInfo.value = data;
    }
    const getLoginInfo = computed(() => loginInfo.value);
    return { loginInfo, setLoginInfo, getLoginInfo };
  });