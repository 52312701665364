<template>
    <template v-if="multiple == true">
        <MultiSelect v-model="model_value" :options="customLabelTextOptions" filter :optionValue="value" :optionLabel="text" :placeholder="placeHolder" :clearable="!required" :disabled="readonly || disabled" display="chip" class="w-100" />
    </template>
    <template v-else>
        <template v-if="readonly || disabled">
            <input :value="getValueText()" class="form-control" type="text" :disabled="true" />
        </template>
        <template v-else>
            <Dropdown v-model="model_value" :options="customLabelTextOptions" filter :optionValue="value" :optionLabel="text" :placeholder="placeHolder" :clearable="!required" class="w-100" />
        </template>
    </template>
</template>

<script>
    import _ from "lodash";

    import Dropdown from 'primevue/dropdown';
    import MultiSelect from 'primevue/multiselect';

    export default {
        name: "NSelect",
        components: {
            Dropdown,
            MultiSelect
        },
        props: {
            modelValue: null,
            options: Array,
            value: {
                type: String,
                default: "value",
            },
            text: {
                type: String,
                default: "text",
            },
            placeHolder: String,
            readonly: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            required: { // 필수여부 이걸로 Clear(X) 버튼 표시 여부
                type: Boolean,
                default: true,
            },
            showValue: { // option 표시할때  텍스트 [밸류] 형태로 표시할지 여부
                type: Boolean,
                default: true,
            },
            multiple: {
                type: Boolean,
                default: false,
            }
        },
        emits: ['update:modelValue', "change"],
        data() {
            return {};
        },
        watch: {
            options() {
                this.onInit();
            }
        },
        mounted() {
            this.onInit();
        },
        methods: {
            onInit() {
                //필수값인데 현재 값이 null 이면 0번째로 강제 세팅
                if (this.required == true
                    && this.options.length > 0
                    && this.modelValue == null) {
                    this.model_value = this.options[0][this.value];
                }
            },
            getValueText() {
                let row = this.customLabelTextOptions.find(r => r[this.value] == this.modelValue);
                if (row != null) {
                    return row[this.text];
                }
                return null;
            }
        },
        computed: {
            model_value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                    this.$emit("change", value);
                },
            },
            customLabelTextOptions: {
                get() {
                    if (typeof (this.showValue) != "undefined" && this.showValue == false) {
                        return this.options;
                    }

                    let list = _.cloneDeep(this.options);
                    if (list.length < 1)
                        return [];

                    let idNameSearchList = ["id", "Id", "ID"];

                    //property 가 존재하면 idnames 의 맨앞에 삽입
                    if (this.value != null && this.value.length > 0) {
                        idNameSearchList.splice(0, 0, this.value);
                    }

                    //키가 있는지 확인하고 있으면 text를 새로 만들어줌
                    for (let id of idNameSearchList) {
                        if (typeof (list[0][id]) !== "undefined" && this.text != id) {
                            for (let item of list) {
                                item[this.text] = `${item[this.text]} [${item[this.value]}]`;
                            }
                            break;
                        }
                    }

                    return list;
                }
            }
        },
    };
</script>
<style>
.p-dropdown-panel {
  z-index: 10000 !important;
}
</style>