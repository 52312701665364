<template>
    <div style="font-size:14px;">
        <div v-if="!disabled" :class="{ 'toolbar-hide': !showToolbar }">
            <QuillEditor theme="snow" :toolbar="toolbarOptions" :modules="modules" v-model:content="content"
                :style="editorStyle" contentType="html" />
        </div>
        <div v-else v-html="content" class="ql-editor-view"></div>
    </div>
</template>

<script>
import { defineComponent, getCurrentInstance } from "vue";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css'; //요건 댓글
import ImageUploader from 'quill-image-uploader';
import ImageResize from 'quill-image-resize-vue';


export default defineComponent({
    name: "NEditor",
    components: {
        QuillEditor,
    },
    setup: () => {
        const { proxy } = getCurrentInstance();
        const modules = [
            {
                name: 'imageUploader',
                module: ImageUploader,
                options: {
                    upload: async (file) => {
                        let formData = new FormData();
                        formData.append("image", file);

                        let data = await proxy.$ajax.multipartAsync('/api/common/img', formData);

                        return data.url;
                    }
                }
            },
            {
                name: 'imageResize',
                module: ImageResize
            }
        ];

        const toolbarOptions = [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'align': [] }],

            //['blockquote', 'code-block'],
            //[{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            //[{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            //[{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            //[{ 'direction': 'rtl' }],                         // text direction
            ['image'],
            ['clean']                                         // remove formatting button
        ];

        return { modules, toolbarOptions }
    },
    props: {
        modelValue: String,
        disabled: {
            type: Boolean,
            default: false
        },
        height: {
            type: Number,
            default: 300
        },
        showToolbar: {
            type: Boolean,
            default: true
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            quillModule: {
                ImageResize: {}
            }
        };
    },
    mounted() {
    },
    methods: {
    },
    computed: {
        content: {
            get() {
                if (!this.modelValue) {
                    return null;
                }

                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
        editorStyle: {
            get() {
                return `min-height: ${this.height}px`;
            }
        }
    }
});
</script>
<style>

.ql-editor {
    background-color: #fff;
    overflow: hidden;
    width: auto;
    min-height: inherit;
}

.ql-editor-view {
    min-height: auto !important;
    padding: 12px 15px 12px 15px;
    background-color: #fff;
    border-radius: 5px;
}

.ql-editor-view p {
    margin: 0;
}

.ql-editor-view img {
    max-width: 100%;
}

.toolbar-hide .ql-container {
    border-top: 1px solid #dee2e6 !important;
}

.toolbar-hide .ql-toolbar {
    display: none;
}

.ql-editor-view img {
    max-width: 100%;
}

</style>