<template>
    <TabView v-model:activeIndex="activeIndex">
        <TabPanel v-for="tab in tabSlots" :key="tab" :header="tab.props.header">
            <NCustomRender :data="tab"></NCustomRender>
        </TabPanel>
    </TabView>
</template>

<script>
    import TabView from 'primevue/tabview';
    import TabPanel from 'primevue/tabpanel';
    import NCustomRender from './NCustomRender.vue';

    export default {
        name: "NTabView",
        components: {
            'TabView': TabView,
            'TabPanel': TabPanel,
            'NCustomRender': NCustomRender
        },
        emits: ["tabChange"],
        data() {
            return {
                activeIndex: null,
                tabSlots: []
            };
        },
        watch: {
            activeIndex(val) {
                this.$emit("tabChange", val, this.tabSlots[0].props);
            }
        },
        mounted() {
            this.refresh();
        },
        methods: {
            refresh() {
                if (typeof (this.$slots) == "undefined")
                    return;

                let slots = this.$slots.default();
                if (slots.length > 0 && ["Symbol(Fragment)", "Symbol(v-fgt)"].indexOf(slots[0].type.toString()) >= 0) {
                    slots = slots[0].children;
                }

                this.activeIndex = null;
                this.tabSlots = slots.filter(s => s.type.name == "NTab");
                this.activeIndex = 0;
            }
        },
    };
</script>
<style>
    .p-tabview-header.p-highlight {
        border: 1px solid #2196F3;
        border-bottom: 0;
        border-radius: 3px 3px 0 0;
    }

        .p-tabview-header.p-highlight .p-tabview-title {
            font-weight: 700;
        }

    .p-tabview .p-tabview-nav {
        border-bottom: 1px solid #dee2e6;
    }

    .p-tabview .p-tabview-panels {
        border: 1px solid #dee2e6;
        border-radius: 0 0 5px 5px;
        border-top: 0;
    }

    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        border: 1px solid #dee2e6;
    }
</style>
