<script>
    import { h } from 'vue';

    export default {
        name: "NDataGridCustomCell",
        props: {
            rowData: Object,
            columnInfo: Object,
            index: Number
        },
        render() {
            let elems = this.columnInfo.children.data({
                row: this.rowData,
                column: this.columnInfo,
                index: this.index
            });

            return h('div', elems.map((e) => h(e)));
        }
    }
</script>
