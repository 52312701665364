<template>
    <date-picker v-model:value="value" type="date" format="YYYY-MM-DD" :clearable="!required" :disabled="disabled" />
</template>

<script>
    import dayjs from 'dayjs/esm/index.js'

    //날짜 컨트롤
    import 'vue-datepicker-next/index.css';
    import DatePicker from 'vue-datepicker-next';

    export default {
        name: "NDatePicker",
        components: { "date-picker": DatePicker },
        props: {
            modelValue: {},
            type: {
                type: String,
                default: 'date'
            },
            required: {
                type: Boolean,
                default: true
            },
            readonly: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
            }
        },
        computed: {
            value: {
                get() {
                    if (typeof this.modelValue === 'string') return new Date(this.modelValue)
                    else return this.modelValue
                },
                set(value) {
                    if (this.type === 'date') this.$emit('update:modelValue', value)
                    else this.$emit('update:modelValue', dayjs(value).format('YYYY-MM-DD HH:mm')) // 초단위 지원은..??
                }
            }
        }
    }
</script>