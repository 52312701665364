import { createRouter, createWebHistory } from 'vue-router'
import { Util, } from '@/util'
import VueCookies from 'vue-cookies'

//name 은 unique 해야하므로 title 로 변경합니다
//role 를 enum 으로 처리해야 오류가 없을지?
const routes = [
    {
        path: '/',
        component: () => import('@/views/layout/_Layout.vue'),
        children: [
            {
                path: '/',
                icon: '',
                title: '',
                visible: false,
                component: () => import('@/views/DashBoardView.vue')
            },
            {
                path: 'info',
                icon: 'fa-crosshairs',
                title: "TEAM BAZOOKA",
                children: [
                    {
                        path: 'about',
                        title: "팀 소개",
                        component: () => import('@/views/info/AboutView.vue')
                    },
                    {
                        path: 'rule',
                        title: "규정",
                        component: () => import('@/views/info/RuleView.vue')
                    },
                    /*
                    {
                        path: 'qna',
                        title: "Q&A",
                        role: ["member"],
                        component: () => import('@/views/info/QnaView.vue')
                    },
                    */
                ]
            },
            {
                path: 'operation',
                icon: 'fa-headset',
                title: "Operation",
                role: ["member"],
                children: [
                    {
                        path: 'schedule',
                        title: "게임일정",
                        component: () => import('@/views/operation/ScheduleView.vue'),
                        children: [
                            {
                                path: ':id',
                                title: "",
                                component: () => import('@/views/operation/ScheduleView.vue'),
                            },
                        ]
                    },
                    /*
                    {
                        path: 'payed',
                        title: "회비납부내역",
                        component: () => import('@/views/operation/PayedView.vue'),
                        visible: false,
                    },
                    */
                ]
            },
            {
                path: 'board',
                icon: 'fa-message',
                title: "커뮤니티",
                role: ["newmember", "member"],
                children: [
                    {
                        path: 'notice',
                        title: "공지사항",
                        role: ["member"],
                        component: () => import('@/views/board/NoticeView.vue'),
                        children: [
                            {
                                path: ':id',
                                title: "",
                                component: () => import('@/views/board/NoticeView.vue'),
                            },
                        ]
                    },
                    {
                        path: 'free',
                        title: "자유게시판",
                        role: ["member"],
                        component: () => import('@/views/board/FreeView.vue'),
                        children: [
                            {
                                path: ':id',
                                title: "",
                                component: () => import('@/views/board/FreeView.vue'),
                            },
                        ]
                    },
                    {
                        path: 'newbie',
                        title: "신입인사",
                        component: () => import('@/views/board/NewbieView.vue'),
                        children: [
                            {
                                path: ':id',
                                title: "",
                                component: () => import('@/views/board/NewbieView.vue'),
                            },
                        ]
                    },
                    /*
                    {
                        path: 'review',
                        title: "장비리뷰",
                        component: () => import('@/views/board/ReviewView.vue')
                    },
                    {
                        path: 'groupbuy',
                        title: "공동구매",
                        component: () => import('@/views/board/GroupBuyView.vue')
                    },
                    {
                        path: 'market',
                        title: "벼룩시장",
                        component: () => import('@/views/board/MarketView.vue')
                    },
                    {
                        path: 'repair',
                        title: "공임요청",
                        component: () => import('@/views/board/RepairView.vue')
                    },
                    */
                ]
            },
            {
                path: 'group',
                icon: 'fa-people-group',
                title: "울트라매니아",
                role: ["member"],
                children: [
                    {
                        path: 'navyseal',
                        title: "Navy SEAL",
                        component: () => import('@/views/groupBoard/NavySealView.vue'),
                        children: [
                            {
                                path: ':id',
                                title: "",
                                component: () => import('@/views/groupBoard/NavySealView.vue'),
                            },
                        ]
                    },
                    {
                        path: 'swat',
                        title: "SWAT",
                        component: () => import('@/views/groupBoard/SwatView.vue'),
                        children: [
                            {
                                path: ':id',
                                title: "",
                                component: () => import('@/views/groupBoard/SwatView.vue'),
                            },
                        ]
                    },
                    {
                        path: 'europeanUnion',
                        title: "European Union",
                        component: () => import('@/views/groupBoard/EuropeanUnionView.vue'),
                        children: [
                            {
                                path: ':id',
                                title: "",
                                component: () => import('@/views/groupBoard/EuropeanUnionView.vue'),
                            },
                        ]
                    },
                    {
                        path: 'pbc',
                        title: "P.B.C",
                        component: () => import('@/views/groupBoard/PBCView.vue'),
                        children: [
                            {
                                path: ':id',
                                title: "",
                                component: () => import('@/views/groupBoard/PBCView.vue'),
                            },
                        ]
                    },
                    {
                        path: 'vsa',
                        title: "V.S.A",
                        component: () => import('@/views/groupBoard/VSAView.vue'),
                        children: [
                            {
                                path: ':id',
                                title: "",
                                component: () => import('@/views/groupBoard/VSAView.vue'),
                            },
                        ]
                    },
                    {
                        path: 'historical',
                        title: "Historical",
                        component: () => import('@/views/groupBoard/HistoricalView.vue'),
                        children: [
                            {
                                path: ':id',
                                title: "",
                                component: () => import('@/views/groupBoard/HistoricalView.vue'),
                            },
                        ]
                    },
                    {
                        path: 'shooters',
                        title: "Shooters",
                        component: () => import('@/views/groupBoard/ShootersView.vue'),
                        children: [
                            {
                                path: ':id',
                                title: "",
                                component: () => import('@/views/groupBoard/ShootersView.vue'),
                            },
                        ]
                    },
                    {
                        path: 'hct',
                        title: "HCT",
                        component: () => import('@/views/groupBoard/HCTView.vue'),
                        children: [
                            {
                                path: ':id',
                                title: "",
                                component: () => import('@/views/groupBoard/HCTView.vue'),
                            },
                        ]
                    },
                    {
                        path: 'plamodel',
                        title: "플라모델",
                        component: () => import('@/views/groupBoard/PlaModelView.vue'),
                        children: [
                            {
                                path: ':id',
                                title: "",
                                component: () => import('@/views/groupBoard/PlaModelView.vue'),
                            },
                        ]
                    },
                ]
            },
            {
                path: 'admin',
                icon: 'fa-cog',
                title: '관리자',
                role: ["admin"],
                children: [
                    {
                        path: 'userManage',
                        title: '회원관리',
                        component: () => import('@/views/admin/UserManageView.vue')
                    },
                    {
                        path: 'OperationAnalysis',
                        title: '게임통계',
                        component: () => import('@/views/admin/OperationAnalysisView.vue')
                    },
                    {
                        path: 'payedManage',
                        title: '연회비관리',
                        component: () => import('@/views/admin/PayedManageView.vue')
                    },
                ]
            },
            {
                path: 'login',
                visible: false,
                children: [
                    {
                        path: '',
                        component: () => import('@/views/login/LoginView.vue')
                    },
                ]
            },
            {
                path: 'mypage',
                visible: false,
                children: [
                    {
                        path: 'myinfo',
                        component: () => import('@/views/mypage/MyInfoView.vue')
                    },
                ]
            },

        ]
    },
    {
        path: '/:pathMatch(.*)*',
        component: () => import('@/views/NotFoundView.vue')
    },
]

//로그인 유저의 role 가져오기
function GetUserRole() {
    let token = VueCookies.get("token");
    let payload = Util.getPayload(token);
    let rolePayload = payload["role"];

    let userRole = [];

    if (typeof (rolePayload) == "string") { //권한이 1개
        userRole.push(rolePayload.toLowerCase());
    }
    else if (Array.isArray(payload["role"])) { //여러개
        for (let role of payload["role"]) {
            //소문자로 변환
            userRole.push(role.toLowerCase());
        }
    }

    return userRole;
}

//active, parent 세팅
function setMenuAttribute(menuArr, parent) {
    if (typeof (parent) === "undefined")
        parent = null;

    let urlPrefix = "";

    if (parent != null) {
        urlPrefix = parent.path;
    }

    let userRole = GetUserRole();

    for (let menu of menuArr) {
        menu.path = menu.path ?? "";
        menu.role = menu.role ?? [];
        menu.visible = menu.visible ?? true;
        menu.children = menu.children ?? [];

        menu.active = false; //활성화 여부 false
        menu.parent = parent; //부모가 있는경우
        menu.showSubmenu = false;

        if (menu.path.length > 0 && menu.path.indexOf('/') != 0) {
            menu.path = `/${menu.path}`;
        }

        if (urlPrefix != "/") {
            menu.path = urlPrefix + menu.path;
        }

        if (menu.role.length > 0) {
            menu.visible = false;

            for (let menuRole of menu.role) {
                if (userRole.includes(menuRole.toLowerCase())) {
                    menu.visible = true;
                    break;
                }
            }
        }

        if (menu.visible == true) {
            if (menu.children.length > 0) { //재귀로 입력
                setMenuAttribute(menu.children, menu);
            }
        }
    }
}
setMenuAttribute(routes);

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from, next) => {
    if (["/login"].indexOf(to.path) > -1) {
        next();
        return;
    }
    try {
        /*
        if (window.$cookies.get("token") == null) {
            next({ path: '/login' });
            return;
        }
        */
        /*
        await AjaxHelper.getAsync("/api/login/checkLogin");
        */
        next();
    }
    catch (e) {
        //실패시
        next({ path: '/login' })
    }

})

export { router, routes }
export default router
