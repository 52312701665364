<template>
    <div ref="modal" class="modal fade modal-lg" data-bs-focus="false" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" :style="modalStyle">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-title fw-bold align-self-center">{{ modalTitle }}</div>
                    <slot name="header"></slot>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                </div>
                <div class="modal-body" :style="bodyStyle">
                    <slot name="default">
                        <!-- Popup body -->
                    </slot>
                </div>
                <div class="modal-footer d-flex ps-2 pe-2">
                    <div class="flex-grow-1 p-0">
                        <slot name="footer"></slot>
                    </div>
                    <div>
                        <button type="button" class="btn btn-outline-light" data-bs-dismiss="modal" @click="close">
                            <i class="fas fa-close"></i> 닫기
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Modal } from 'bootstrap'

    export default {
        name: 'NModal',
        props: {
            title: {
                type: String,
                default: null
            },
            width: {
                type: Number,
                default: null
            },
            height: {
                type: Number,
                default: null
            },
            addAdminReason: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                modalObj: null,
                adminReason: null,
                modalStyle: {
                    'max-width': '1300px'
                },
                bodyStyle: {}
            }
        },
        computed: {
            modalTitle() {
                if (this.title == null || this.title.length > 0) {
                    return "";
                }
                return this.title;
            }
        },
        created() {
            if (this.width != null) {
                this.modalStyle["max-width"] = this.width + "px";
            }

            if (this.height != null) {
                this.bodyStyle["min-height"] = `${this.height}px`;
            }
        },
        mounted() {
            this.modalObj = new Modal(this.$refs["modal"], {
                backdrop: 'static',
                keyboard: false,
                focus: true
            });

            this.$refs["modal"].getModalObject = () => this.modalObj;
            this.$refs["modal"].getAdminReason = () => this.adminReason;
        },
        methods: {
            show() {
                this.adminReason = null;
                this.modalObj.show();
            },
            close() {
                this.modalObj.hide();
            },
        },
    }
</script>