<template>
    <div class="p-inputgroup">
        <InputNumber v-model="value" mode="decimal" :min="min" :max="max" :disabled="disabled" />
        <slot></slot>
    </div>
</template>

<script>
    import InputNumber from 'primevue/inputnumber';

    export default {
        name: "NInputNumber",
        components: {
            'InputNumber': InputNumber,
        },
        props: {
            modelValue: Number,
            min: null,
            max: null,
            disabled: null
        },
        data() {
            return {
            };
        },
        computed: {
            value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                }
            }
        },
        mounted() {
        },
        methods: {
        },
    };
</script>
<style>
    span.p-inputnumber {
        width: 100%;
    }

    input.p-inputnumber-input {
        text-align: right;
        font-size: 14px;
    }

        input.p-inputnumber-input:disabled {
            background-color: #e9ecef;
            opacity: 1;
            color: #212529;
        }

    .p-inputgroup button {
        font-size: inherit;
        padding: 4px 8px 4px 8px;
    }

    .p-inputgroup input {
        padding: 6px;
    }

        .p-inputgroup-addon:last-child,
        .p-inputgroup button:last-child,
        .p-inputgroup input:last-child,
        .p-inputgroup > .p-inputwrapper:last-child,
        .p-inputgroup > .p-inputwrapper:last-child > .p-inputtext {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
        }
</style>