<template>
    <TabView>
        <TabPanel v-for="tab in tabSlots" :key="tab" :header="tab.props.header">
            <NTabRender :tab="tab"></NTabRender>
        </TabPanel>
    </TabView>
</template>

<script>
    import TabView from 'primevue/tabview';
    import TabPanel from 'primevue/tabpanel';
    import NTabRender from './NTabRender.vue';

    export default {
        name: "NTabView",
        components: {
            'TabView': TabView,
            'TabPanel': TabPanel,
            'NTabRender': NTabRender
        },
        props: {
        },
        emits: ['update:modelValue', "change"],
        data() {
            return {
                tabSlots: []
            };
        },
        mounted() {
            if (typeof (this.$slots) == "undefined")
                return;

            let slots = this.$slots.default();
            this.tabSlots = slots.filter(s => s.type.name == "NnTab");

            //let slots = this.$slots.default();
        },
        methods: {
        },
    };
</script>
<style>
    .p-tabview-header.p-highlight {
        border: 1px solid #2196F3;
        border-bottom: 0;
        border-radius: 3px 3px 0 0;
    }
</style>
