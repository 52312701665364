import Swal from 'sweetalert2'

const Alert = {
    defaultOption: {
        allowEscapeKey: false,
        allowOutsideClick: false,
        focusConfirm: true
    },
    alertAsync: async function (messageText, options) {
        Swal.hideLoading();

        if (typeof (options) === "undefined") {
            options = {};
        }
        options["allowOutsideClick"] = false;

        //icon: info, error, success, question
        if (typeof (options["icon"]) === "undefined")
            options["icon"] = "info";


        if (typeof (options["html"]) === "undefined") {
            options["text"] = messageText;
        }

        if (!options["text"]) {
            options["text"] = messageText;
        }

        Object.assign(options, this.defaultOption);

        let result = null;

        let elem = document.querySelector(".modal.show");
        if (elem != null) {
            let modal = elem.getModalObject();
            modal._focustrap.deactivate();
            result = await Swal.fire(options);
            modal._focustrap.activate();
        }
        else {
            result = await Swal.fire(options);
        }

        return result;
    },
    confirmAsync: async function (questionMessage, options) {
        Swal.hideLoading();

        if (typeof (options) === "undefined") {
            options = {};
        }

        options["showCancelButton"] = true;

        //icon: info, error, success, question
        if (typeof (options["icon"]) === "undefined")
            options["icon"] = "question";

        if (typeof (options["confirmButtonText"]) === "undefined")
            options["confirmButtonText"] = "저장";

        if (typeof (options["cancelButtonText"]) === "undefined")
            options["cancelButtonText"] = "취소";

        options["title"] = questionMessage;

        if (typeof (options["preConfirm"]) !== "undefined") {
            options["showLoaderOnConfirm"] = true;
        }

        //console.log(options);
        Object.assign(options, this.defaultOption);

        let elem = document.querySelector(".modal.show");
        let result = null;
        if (elem != null) {
            let modal = elem.getModalObject();
            modal._focustrap.deactivate();
            result = await Swal.fire(options);
            modal._focustrap.activate();
        }
        else {
            result = await Swal.fire(options);
        }

        return result.isConfirmed;
    },
    confirmInputReasonAsync: async function (questionMessage, options) {
        Swal.hideLoading();

        if (typeof (options) === "undefined") {
            options = {};
        }

        options["input"] = "text";
        options["inputLabel"] = "common.admin_reason";

        options["showCancelButton"] = true;

        //icon: info, error, success, question
        if (typeof (options["icon"]) === "undefined")
            options["icon"] = "question";

        if (typeof (options["confirmButtonText"]) === "undefined")
            options["confirmButtonText"] = "저장";

        if (typeof (options["cancelButtonText"]) === "undefined")
            options["cancelButtonText"] = "취소";

        options["title"] = questionMessage;

        if (typeof (options["preConfirm"]) !== "undefined") {
            options["showLoaderOnConfirm"] = true;
        }

        //console.log(options);
        Object.assign(options, this.defaultOption);

        let elem = document.querySelector(".modal.show");
        let result = null;
        if (elem != null) {
            let modal = elem.getModalObject();
            modal._focustrap.deactivate();
            result = await Swal.fire(options);
            modal._focustrap.activate();
        }
        else {
            result = await Swal.fire(options);
        }

        return {
            value: result.isConfirmed,
            reason: result.value
        };
    },
    deleteConfirmAsync: async function (options) {
        return await this.confirmAsync("삭제하시겠습니까?", options);
    },
    updateConfirmAsync: async function (options) {
        return await this.confirmAsync("수정하시겠습니까?", options);
    },
    saveConfirmAsync: async function (options) {
        return await this.confirmAsync("저장하시겠습니까?", options);
    },
    errorShowAsync: async function (errorObj) {
        if (errorObj == null)
            errorObj = {};

        if (typeof (errorObj) == "string") {
            errorObj = {
                Message: errorObj
            }
        }

        let errorSeq = errorObj.ErrorSeq ?? -1;
        let message = errorObj.Message ?? "UndefinedError";
        let errorParam = errorObj.ErrorParam;

        let text = "error";
        if (errorParam != null)
            text = `[${errorSeq}] ${message} ${errorParam}`;
        else
            text = `[${errorSeq}] ${message}`;


        return this.alertAsync(text, { icon: "warning" });
    },
    showLoading: function (loadingMessage) {
        if (typeof (loadingMessage) === "undefined") {
            loadingMessage = "Loading...";
        }

        let option = {
            title: loadingMessage,
            allowOutsideClick: false,
            didOpen: function () {
                Swal.showLoading();
            }
        };
        Object.assign(option, this.defaultOption);

        Swal.fire(option);
    },
    closeLoading: function () {
        Swal.close();
    }
}

export default Alert

