<script>
import { h } from 'vue';

export default {
    name: "NCustomRender",
    props: {
        data: Object
    },
    render() {
        let elems = this.data.children.default();

        return h('div', elems.map((e) => h(e)));
    }
}
</script>
