import dayjs from 'dayjs'
import _ from 'lodash'

import { mapActions } from "pinia";
import { loginInfoStore } from '@/store';

const Util = {
    isEmpty: (val) => (val === null || typeof (val) === 'undefined' || ['', 'null', 'undefined'].includes(val)),
    clone: (data) => _.cloneDeep(data),
    getUUID: () => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    ),
    moveToLogin() {
        location.replace("/login");
    },
    getToday() { //오늘 날짜의 00:00:00 을 반환합니다
        var d = new Date();

        return new Date(d.getFullYear(), d.getMonth(), d.getDate());
    },
    isDate: function (val) {
        if (Object.prototype.toString.call(val) === "[object Date]") {
            return true;
        }
        else if (typeof (val) === "string" && val.length >= 8 && dayjs(val).isValid()) {
            return true;
        }

        return false;
    },
    toLocalDateString: function (val) {
        let year = val.getFullYear();
        let month = ("0" + (val.getMonth() + 1)).slice(-2)
        let day = ("0" + val.getDate()).slice(-2)
        let hour = ("0" + val.getHours()).slice(-2)
        let min = ("0" + val.getMinutes()).slice(-2)
        let sec = ("0" + val.getSeconds()).slice(-2);

        //만약 DateTimeOffset으로 UTC 관리를 한다면
        //.getTimezoneOffset() 을 통해서 +09:00 형태로 만들어줘야함
        return `${year}-${month}-${day}T${hour}:${min}:${sec}`;
    },
    format: function (format, val) {
        if (typeof (val) == "undefined" || val == null)
            return null;

        if (format == "date") {
            return dayjs(val).format("YYYY.MM.DD")
        }
        else if (format == "datetime") {
            return dayjs(val).format("YYYY.MM.DD HH:mm:ss")
        }
        else if (format == "shortdatetime") {
            return dayjs(val).format("YY.MM.DD HH:mm")
        }
        else if (format == "shorttime") {
            let day = dayjs(val);
            let now = dayjs();

            if (day.date() == now.date()) {
                return dayjs(val).format("HH:mm");
            }
            
            return dayjs(val).format("YYYY.MM.DD")
        }
        else if (format == "number") {
            return this.addCommas(val);
        }
        else {
            if (this.isDate(val) === true) {
                return this.format("shorttime", val );
            }
        }

        return val;
    },
    getLoginInfo() {
        let token = window.$cookies.get("token");

        let payload = this.getPayload(token);

        return payload;
    },
    getPayload(token) {
        if (this.isEmpty(token))
            return {}

        let base64Payload = token.split('.')[1];
        if (base64Payload.length < 1)
            return {}

        let base64 = base64Payload.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload)
    },
    isLogin() {
        let login = this.getLoginInfo();
        if (login != null)
            return true;

        return false;
    },
    isInRole(role) {
        let login = this.getLoginInfo();
        if (login == null || login.name == null)
            return false;
        
        if (login["role"].indexOf(role) > -1) {
            return true;
        }

        return false;
    },
    ...mapActions(loginInfoStore, ["setLoginInfo"]),
    loginRefresh() {
        var login = this.getLoginInfo();
        this.setLoginInfo(login);
    },
    addCommas(s) {
        var input = String(s);
        var output = "";

        for (var i = input.length; i >= 0; i--) {

            if ((input.length - i) % 3 == 1 && output.length != 0 && input.charAt(i) != "-") {

                output = "," + output;

            }

            output = input.charAt(i) + output;

        }

        return output;
    },
    isDateOverCheck(val) {
        return dayjs().isAfter(dayjs(val));
    }

}

export default Util

