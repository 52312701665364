<template>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="d-flex flex-row mb-1 mb-md-0">
                    <div class="align-self-center" style="min-width:30px">기간</div>
                    <div class="ms-2">
                        <NDateTimePicker v-model="fromVal" />
                    </div>
                    <div class="align-self-center ms-1">~</div>
                    <div class="ms-1">
                        <NDateTimePicker v-model="toVal" />
                    </div>
                    
                    <button v-on:click="onSearch" class="btn btn-primary ps-3 pe-3 ms-2">검색</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import dayjs from 'dayjs/esm/index.js'

    export default {
        name: "NDateTimeRangeBox",
        props: {
            from: [String, Date],
            to: [String, Date],
        },
        data() {
            return {
                local: {
                    from: null,
                    to: null
                }
            }
        },
        computed: {
            fromVal: {
                get() {
                    if (typeof (this.from) !== "undefined") {
                        return this.from;
                    }
                    else {
                        return this.local.from;
                    }
                },
                set(value) {
                    if (typeof (this.from) !== "undefined") {
                        this.$emit('update:from', value)
                    }
                    else {
                        this.local.from = value;
                    }
                }
            },
            toVal: {
                get() {
                    if (typeof (this.to) !== "undefined") {
                        return this.to;
                    }
                    else {
                        return this.local.to;
                    }
                },
                set(value) {
                    if (typeof (this.to) !== "undefined") {
                        this.$emit('update:to', value)
                    }
                    else {
                        this.local.to = value;
                    }
                }
            }
        },
        mounted() {
            let baseDay = this.getToday();

            if (typeof (this.from) !== "undefined" && dayjs(this.from).isValid() == true) {
                this.fromVal = this.from;
            }
            else if (this.from == null){
                this.fromVal = dayjs(baseDay).add(-3, 'M').toDate();
            }

            if (typeof (this.to) !== "undefined" && dayjs(this.to).isValid() == true) {
                this.toVal = this.to;
            }
            else if (this.to == null) {
                this.toVal = dayjs(baseDay).add(1, 'M').toDate();
            }
        },
        methods: {
            onSearch() {
                this.$emit(`search`, this.fromVal, this.toVal);
            },
            getToday() {
                var d = new Date();

                return new Date(d.getFullYear(), d.getMonth(), d.getDate());
            },
        },
    }
</script>