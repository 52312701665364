import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import '@fortawesome/fontawesome-free/css/all.css';
import "@/assets/tabler/css/tabler.min.css";
//import "@/assets/tabler/js/tabler.min.js";
import "@/assets/app.css";
import "@/assets/dark.css";

import { createPinia } from 'pinia'
//import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

const pinia = createPinia();
//pinia.use(piniaPluginPersistedstate);

import VueCookies from 'vue-cookies'

import { AjaxHelper, Util, Alert } from '@/util'
//import mitt from 'mitt';

//날짜 
import dayjs from 'dayjs/esm/index.js'

//프라임뷰
import PrimeVue from 'primevue/config';
import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/saga-blue/theme.css";
import "primeicons/primeicons.css"

//전역 컴포넌트 설정 파일
import NDataGrid from '@/components/NDataGrid.vue'
import NDataGridColumn from '@/components/NDataGridColumn.vue'
import NDatePicker from "@/components/NDatePicker";
import NDateTimePicker from "@/components/NDateTimePicker";
import NDateTimeRangeBox from "@/components/NDateTimeRangeBox";
import NSelect from "@/components/NSelect";
import NTabView from "@/components/NTabView";
import NTab from "@/components/NTab";
import NInputNumber from "@/components/NInputNumber";

import NModal from "@/components/NModal";
import NEditor from "@/components/NEditor";

let _app = null;
_app = createApp(App)
    .use(pinia)
    .use(router)
    .use(VueCookies)
    .use(PrimeVue);
    

//const emitter = mitt();
//app.config.globalProperties.emitter = emitter;
_app.config.globalProperties.$dayjs = dayjs;

_app.config.globalProperties.$ajax = AjaxHelper;
_app.config.globalProperties.$util = Util;
_app.config.globalProperties.$alert = Alert;

_app
    .component('NDataGrid', NDataGrid)
    .component('NDataGridColumn', NDataGridColumn)
    .component('NDatePicker', NDatePicker)
    .component('NDateTimePicker', NDateTimePicker)
    .component('NDateTimeRangeBox', NDateTimeRangeBox)
    .component('NTabView', NTabView)
    .component('NTab', NTab)
    .component('NSelect', NSelect)
    .component('NInputNumber', NInputNumber)
    .component('NModal', NModal)
    .component('NEditor', NEditor)
    ;

_app.mount('#app');

/*
Date 객체를 LocalDate 기준으로 정규화 처리
*/
//toJSON 문제 처리, 초 미만은 버린다
Date.prototype.toJSON = function () {
    let year = this.getFullYear();
    let month = ("0" + (this.getMonth() + 1)).slice(-2)
    let day = ("0" + this.getDate()).slice(-2)
    let hour = ("0" + this.getHours()).slice(-2)
    let min = ("0" + this.getMinutes()).slice(-2)
    let sec = ("0" + this.getSeconds()).slice(-2);

    //만약 DateTimeOffset으로 UTC 관리를 한다면
    //.getTimezoneOffset() 을 통해서 +09:00 형태로 만들어줘야함
    return `${year}-${month}-${day}T${hour}:${min}:${sec}`;
}

Date.prototype.toURI = function () {
    return encodeURIComponent(this.toJSON());
}
