<template>
    <div class="card">
        <!--currentPageReportTemplate="{first} ~ {last} ({totalRecords})"-->

        <DataTable ref="dt" class="p-datatable-sm" showGridlines responsiveLayout="scroll" removableSort sortMode="multiple" :scrollable="true" :scrollHeight="heightValue" :virtualScrollerOptions="virtualScrollerOptions" :value="sourceRows" v-model:selection="localSelectionRows" :selectionMode="selectionMode" :metaKeySelection="false" @rowSelect="onRowSelect" @rowUnselect="onRowUnselect" :tableStyle="tableStyle" :lazy="isServerSidePaging" :loading="loading" :totalRecords="totalRecords" :resizableColumns="true" columnResizeMode="fit" :reorderableColumns="true" @columnReorder="onColReorder" :rowHover="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="" :paginator="allowPaging" v-model:rows="pageRowCount" :rowsPerPageOptions="rowsPerPageOptions" @page="onPageChangeAsync" v-model:filters="filterOptions" :filterDisplay="filterMode">
            <template #header v-if="isShowHeader">
                <div class="row">
                    <div class="col-6 text-start align-self-center">
                        <div class="p-datatable-title" v-if="title != null">{{ title }}</div>
                    </div>
                    <div class="col-6 text-end">
                        <button v-if="allowExport && exportName" @click="exportExcel" class="btn btn-sm btn-outline-light"><i class="fa-solid fa-file-excel"></i> Excel</button>
                    </div>
                </div>
            </template>

            <Column v-if="selectionMode == 'multiple'" selectionMode="multiple" style="flex: 0 0 32px;padding-left:5px;width:30px;"></Column>

            <Column v-for="(col) in columns.filter(c => c.visible == true)" :visible="false" v-bind:key="col.field" :field="col.field" :header="col.header" :sortable="allowSort && col.allowSort" :style="col.colStyle" :dataType="col.columnType" :frozen="col.frozen">
                <template v-if="col.dataType == 'custom'" #body="{ data, index }">
                    <NDataGridCustomCell :rowData="data" :columnInfo="col" :index="index" />
                </template>
                <template v-else-if="col.dataType == 'enum'" #body="{ data }">
                    {{ getEnumText(col.enumType, data[col.field], col.isShowValue) }}
                </template>
                <template v-else-if="col.dataType == 'asset'" #body="{ data }">
                    <span v-if="col.localization" v-html="getAssetText(col.assetFile, data[col.field], col.assetColumn, col.localization, col.isShowValue)"></span>
                    <span v-else>
                        {{ getAssetText(col.assetFile, data[col.field], col.assetColumn, col.localization, col.isShowValue) }}
                    </span>
                </template>
                <template v-else-if="col.dataType == 'assetEnum'" #body="{ data }">
                    {{ getAssetEnumText(col.assetFile, data[col.field], col.assetColumn, col.enumType, col.isShowValue) }}
                </template>
                <template v-else-if="col.dataType != null" #body="{ data }">
                    {{ $util.format(col.dataType, data[col.field]) }}
                </template>

                <!-- 편집기능, isEdit 와 editType 으로 나눌까 하다가 editType이 있으면 편집가능하도록 변경 -->
                <template v-else-if="col.editType == 'string'" #body="{ data }">
                    <input v-model="data[col.field]" class="form-control" type="text" />
                </template>

                <template #filter="{}">
                    <InputText type="text" v-model="filterOptions[col.field].value" class="p-column-filter" placeholder="" />
                </template>
            </Column>

            <template #paginatorstart>
                Total: {{ totalRecords }}
            </template>
            <template #paginatorend>
                <!-- 이게 없으면 중앙 정렬이 안맞음-->
            </template>

            <template #empty>
                message.no_search_data
            </template>

            <template #loading>
                Data Loading....
            </template>
        </DataTable>
    </div>
</template>
<script>
    import NDataGridCustomCell from './NDataGridCustomCell';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import { FilterMatchMode } from "primevue/api";
    import InputText from 'primevue/inputtext';

    //import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
    //import Row from 'primevue/row';                     //optional for row
    export default {
        name: "NDataGrid",
        components: {
            NDataGridCustomCell,
            DataTable,
            Column,
            InputText,
        },
        props: {
            title: String,
            width: {
                type: Number,
                default: null
            },
            height: {
                type: Number,
                default: null
            },
            allowPaging: { //페이징여부
                type: Boolean,
                default: true,
            },
            allowSort: { //정렬기능
                type: Boolean,
                default: true,
            },
            allowExport: { //Excel Export 버튼 보이기
                type: Boolean,
                default: true,
            },
            url: { //서버사이드 페이징시 URL
                type: String,
                default: null,
            },
            rows: Array,
            filterMode: { //저장시 이름 변경
                type: String,
                default: null
            },
            exportName: { //저장시 이름 변경
                type: String,
                default: null
            },
            autoGenerateColumns: { //값에 맞게 자동 컬럼 생성 (0번째 행으로)
                type: Boolean,
                default: false
            },
            headerPrefix: { //headerText
                type: String,
                default: null
            },
            selectionMode: {
                type: String,
                default: null
            },
            showHeader: {
                type: Boolean,
                default: true
            },
            loading: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                columns: [],
                pageRowCount: 25,
                pageIndex: 1,
                alertLoading: false,
                totalRecords: 0,
                isServerSidePaging: false,
                virtualScrollerOptions: null,
                localData: [],
                localSelectionRows: [],
                filterOptions: {},
                rowsPerPageOptions: [25, 50, 100],
                tableStyle: ""
            }
        },
        watch: {
            width() {
                this.onInitTableStyle();
            },
            rows(val) {
                //서버사이드 페이징의 경우 동작할 필요 없음
                if (this.isServerSidePaging == true) {
                    return;
                }

                this.$refs["dt"].d_first = 0; //1페이지로

                if (val != null) {
                    this.totalRecords = val.length;
                }
                else {
                    this.totalRecords = 0;
                }

                if (this.autoGenerateColumns) {
                    this.onInit();
                }
            },
            allowPaging() {
                this.onPagingInit();
            }
        },
        computed: {
            isShowHeader() {
                let flag = false;

                if (this.showHeader == true) {
                    //타이틀이 있거나
                    if (this.title != null && this.title.length > 0) {
                        flag = true;
                    }

                    //excel 내보내기 기능이 있어야
                    if (this.allowExport && this.exportName) {
                        flag = true;
                    }
                }

                return flag;
            },
            heightValue() {
                if (typeof (this.height) != "undefined" && this.height != null) {
                    return this.height + "px";
                }

                if (this.allowPaging == true) {
                    return "300px"; //default
                }

                return null;
            },
            firstIndex() {
                return this.$refs["dt"].d_first;
            },
            sourceRows: {
                get() {
                    if (this.rows != null) {
                        return this.rows;
                    }
                    else {
                        return this.localData;
                    }
                },
                set(val) {
                    if (this.allowPaging == false) {
                        if (this.rows.length > 1000) {
                            this.virtualScrollerOptions = { itemSize: 25 };
                        }
                        else {
                            this.virtualScrollerOptions = null;
                        }
                    }

                    if (this.rows != null) {
                        this.$emit("update:rows", val);
                    }
                    else {
                        this.localData = val;
                    }
                }
            }
        },
        created() {
            if (this.url != null) {
                this.isServerSidePaging = true;
            }
            else {
                this.isServerSidePaging = false;
            }
        },
        async mounted() {
            await this.onInit();
        },
        methods: {
            onInitTableStyle() {
                this.tableStyle = {};

                if (this.width) {
                    this.tableStyle["min-width"] = `${this.width}px`;
                }

                if (this.heightValue) {
                    this.tableStyle["max-height"] = this.heightValue;
                }
            },
            async onInit() {
                this.onInitTableStyle();

                let slots = [];

                if (typeof (this.$slots) != "undefined" && typeof (this.$slots.default) != "undefined") {
                    slots = this.$slots.default();
                }

                let columnSlots = slots.filter(s => s.type.name == "NDataGridColumn");

                if (this.autoGenerateColumns) {
                    if (this.rows.length > 0) {
                        let generateColumns = [];

                        //첫번째 row의 컬럼으로 데이터를 채워넣습니다.
                        let firstRow = this.rows[0];

                        //row column 기준으로 데이터넣기, 만약 정의된 컬럼이 있다면 정의된 컬럼데이터로 대체
                        for (let fieldName of Object.keys(firstRow)) {
                            let defineCol = columnSlots.find(s => s.props["field"] == fieldName);

                            if (defineCol != null) {
                                generateColumns.push(defineCol);
                            }
                            else {
                                generateColumns.push({
                                    props: {
                                        field: fieldName
                                    }
                                });
                            }
                        }

                        //정의된 컬럼중 누락된게 있다면 추가 --> 옵션으로 해야할듯
                        for (let defineCol of columnSlots) {
                            if (generateColumns.findIndex(c => c.props["field"] == defineCol.props["field"]) < 0) {
                                generateColumns.push(defineCol);
                            }
                        }

                        columnSlots = generateColumns;
                    }
                }

                this.filterOptions = {};
                let newColumnArr = [];

                for (let col of columnSlots) {
                    let filter = {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    };

                    let newCol = {};
                    newCol["colStyle"] = {};

                    if (col.props["align"]) {
                        newCol["colStyle"]["text-align"] = col.props["align"];
                    }

                    newCol["header"] = col.props["header"] ?? col.props["field"];
                    
                    if (this.headerPrefix != null && newCol["header"].indexOf(".") < 0) { //headerPrefix가 없거나 값에 .이 있는경우는 header 로 바로
                        newCol["header"] = `${this.headerPrefix}.${newCol["header"]}`;
                    }
                    

                    if (col.props["visible"] != null) {
                        newCol["visible"] = col.props["visible"];
                    }
                    else {
                        newCol["visible"] = true;
                    }

                    if (col.props["field"] != null) {
                        newCol["field"] = col.props["field"];
                    }

                    if (col.props["width"] != null) {
                        if (col.props["width"] <= 0) { //width가 0이면 숨김
                            newCol["visible"] = false;
                        }
                        newCol["colStyle"]["min-width"] = `${col.props["width"]}px`;
                        newCol["colStyle"]["max-width"] = `${col.props["width"]}px`;

                        /* 동작하지 않음?
                        if (this.allowPaging == true) {
                            newCol["colStyle"]["flex"] = `0 0 ${col.props["width"]}px`;
                        }
                        */
                    }

                    //template 을 노가다로 넣어주는 수 밖에 없나?
                    newCol["columnType"] = "text";
                    if (typeof (col.props["editType"]) != "undefined") {
                        newCol["editType"] = col.props["editType"];
                    }
                    else {
                        newCol["editType"] = null;
                    }


                    if (col.props["dataType"] != null) {
                        newCol["dataType"] = col.props["dataType"];

                        if (col.props["dataType"] == "custom") {
                            newCol["children"] = col.children;
                        }
                        else if (col.props["dataType"] == "number") {
                            newCol["columnType"] = "numeric";
                        }
                        else if (newCol["dataType"] == "enum" && col.props["enumType"] != null) {
                            newCol["enumType"] = col.props["enumType"];

                            //enum Load
                            await this.$util.getEnumDicAsync(newCol["enumType"]);
                        }
                        else if (newCol["dataType"] == "asset" || newCol["dataType"] == "assetEnum") {
                            newCol["assetFile"] = col.props["assetFile"];
                            newCol["assetColumn"] = col.props["assetColumn"];

                            //asset Load
                            await this.$util.getAssetDicAsync(newCol["assetFile"]);

                            if (newCol["dataType"] == "assetEnum") {
                                newCol["enumType"] = col.props["enumType"];

                                //enum Load
                                await this.$util.getEnumDicAsync(newCol["enumType"]);
                            }
                        }
                        else if (newCol["dataType"] == "date" || newCol["dataType"] == "datetime") {
                            newCol["columnType"] = "date";
                            filter.matchMode = FilterMatchMode.DATE_IS;
                        }
                    }

                    newCol["localization"] = col.props["localization"];
                    if (typeof (newCol["localization"]) == "undefined") {
                        newCol["localization"] = true; //로컬라이징 기본 true
                    }

                    newCol["isShowValue"] = col.props["isShowValue"];
                    if (typeof (newCol["isShowValue"]) == "undefined") {
                        newCol["isShowValue"] = true; //[{value}] 표시여부
                    }

                    newCol["frozen"] = false;
                    if (col.props["frozen"] != null) {
                        if (col.props["frozen"] == true) {
                            newCol["frozen"] = true;
                        }
                    }

                    //server side paging 시 삭제
                    newCol["allowSort"] = true;

                    if (this.isServerSidePaging == true) {
                        newCol["allowSort"] = false;
                    }

                    this.filterOptions[newCol["field"]] = filter;

                    newColumnArr.push(newCol);
                }
                this.columns = newColumnArr;

                if (this.isServerSidePaging == true) {
                    await this.onLoadAsync();
                }

                this.onPagingInit();
            },
            onPagingInit() {
                if (this.allowPaging == false) {
                    if (this.rows.length > 1000) {
                        this.virtualScrollerOptions = { itemSize: 25 };
                    }
                    else {
                        this.virtualScrollerOptions = null;
                    }
                    this.pageRowCount = 100000;

                    this.rowsPerPageOptions = [];
                }
                else {
                    this.pageRowCount = 25;
                    this.rowsPerPageOptions = [25, 50, 100];
                }
            },
            onColReorder() {

            },
            async onPageChangeAsync(e) {
                this.pageIndex = e.page;

                if (this.isServerSidePaging == true) {
                    this.alertLoading = true;

                    let separator = "?";
                    if (this.url.indexOf('?') > -1) {
                        separator = "&";
                    }
                    let url = `${this.url}${separator}skip=${e.first}&take=${e.rows}`;
                    let data = await this.$ajax.getAsync(url);

                    this.sourceRows = data; //emit 을 통해 바인딩값을 변경하기때문에 비동기로 변경이 시작된다
                    this.alertLoading = false;
                }
            },
            async onLoadAsync() {
                if (this.isServerSidePaging != true)
                    return;

                this.alertLoading = true;

                let separator = "?";
                if (this.url.indexOf('?') > -1) {
                    separator = "&";
                }

                let url = `${this.url}${separator}getTotalCount=true`;

                let res = await fetch(url);
                let data = await res.json();

                this.totalRecords = data.totalCount;

                this.$refs["dt"].d_first = 0; //1페이지로

                this.$nextTick(() => {
                    let page = this.$refs["dt"].$el.querySelector(".p-paginator-pages").querySelector(".p-paginator-page");
                    if (page != null) {
                        page.dispatchEvent(new Event('click'));
                    }
                })

                this.alertLoading = false;
            },
            onRowSelect(evt) {
                this.$emit("rowSelect", evt);
            },
            onRowUnselect(evt) {
                this.$emit("rowUnselect", evt);
            },
            GetSelectionRows() {
                return this.localSelectionRows;
            },
            getEnumText(enumType, val, isShowValue) {
                return this.$util.getEnumText(enumType, val, isShowValue);
            },
            getAssetText(assetFile, val, assetColumn, isLocalization, isShowValue) {
                let str = this.$util.getAssetInfo(assetFile, val, assetColumn, isLocalization, isShowValue);

                if (isLocalization) {
                    return this.$util.ConvertUnityTag(str);
                }

                return str;
            },
            getAssetEnumText(assetFile, val, assetColumn, enumType, isShowValue) {
                return this.$util.getAssetEnumInfo(assetFile, val, assetColumn, enumType, isShowValue);
            }
        },
    }
</script>
<style>
        .p-component {
            font-size: 0.8rem;
        }

        .p-datatable-header {
            background-color: #274472 !important;
            color: #fff !important;
        }

        /*
    .p-datatable-title {}
    */

        .p-datatable-thead > tr > th {
            border-top: 0 !important;
            border-bottom: 0 !important;
        }


        .p-datatable-thead > tr > th {
            line-height: 1;
            background-color: #eee !important;
        }

        .p-datatable-thead .p-sortable-column-icon {
            font-size: 0.8rem;
        }

        .p-paginator {
            background-color: #efefef;
            padding: 3px 15px 3px 15px;
            color: #000;
        }

        .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
            padding: 0.3rem;
        }

        .p-paginator-page.p-paginator-element.p-link.p-highlight {
            background-color: #4783ff;
            color: #fff;
            font-weight: bold;
        }

        .p-paginator .p-link,
        .p-paginator .p-dropdown-label.p-inputtext {
            font-size: 0.75rem;
        }

        .p-paginator .p-paginator-pages .p-paginator-page,
        .p-paginator .p-paginator-current,
        .p-paginator .p-paginator-first,
        .p-paginator .p-paginator-prev,
        .p-paginator .p-paginator-next,
        .p-paginator .p-paginator-last,
        .p-paginator .p-dropdown {
            height: 1.7rem;
        }

            .p-paginator .p-dropdown .p-inputtext {
                padding: 0;
                padding-top: 3px;
                padding-left: 8px;
            }

        .p-paginator .p-disabled,
        .p-disabled * {
            color: transparent;
        }

        .p-hidden-space.p-column-filter-clear-button.p-link {
            display: none;
        }

        .p-datatable-resizable .p-datatable-thead > tr > th,
        .p-datatable-resizable .p-datatable-tfoot > tr > td,
        .p-datatable-resizable .p-datatable-tbody > tr > td {
            overflow: none;
            white-space: normal;
            word-break: break-all;
        }
</style>
