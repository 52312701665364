import axios from "axios"
import BigInt from 'json-bigint'

import util from "./Util.js"
import alert from "./Alert.js"
import Swal from "sweetalert2"

const createAxios = () => {
    let options = {
        baseURL: '',
        timeout: 3 * 60 * 1000,
        headers: {
            'Access-Control-Allow-Origin': '*',
            //'Authorization': window.$cookies.get("token")
        },
        transformResponse: (data) => {
            if (typeof (data) == "object") {
                return data;
            }

            if (data.length == 0) {
                return null;
            }

            return BigInt().parse(data);
        }
    }

    const axiosInstance = axios.create(options)

    axiosInstance.interceptors.request.use(
        async function (config) { // 요청 시 config에 담은 값으로 여기서 활용 가능
            //console.log(config);
            config.headers['X-Request-Id'] = util.getUUID();

            //이걸 자동화 하는게 맞는건가??, 분리해서 그때그때 넣도록 option 처리하는게 맞을것 같기도 함
            //modal이 열려있으면  모달의 adminreason 을 가져다가 header 에 입력
            let elem = document.querySelector(".modal.show");
            if (elem != null) {
                let reason = elem.getAdminReason();
                if (reason != null) {
                    config.headers["X-ADMIN-REASON"] = encodeURIComponent(reason);
                }
            }

            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    )

    axiosInstance.interceptors.response.use(
        (response) => response,
        (error) => Promise.reject(error)
    )

    return axiosInstance;
}


const AjaxHelper = {
    __instance: createAxios(),
    __tokenRefreshCount: 0,
    async callAsync(method, url, options) {
        let isTokenRefresh = false;
        let option = {
            method: method,
            url: url,
        }

        if (typeof (options) === "undefined" || options == null) {
            options = {};
        }

        Object.assign(option, options);

        try {
            let response = await this.__instance(option);

            if (typeof (response) === "undefined") {
                return null;
            }

            if (typeof (option.responseType) != "undefined" && option.responseType == "blob") { //다운로드 라면 response 반환
                return response;
            }

            return response.data;
        }
        catch (e) {
            setTimeout(() => {
                //console.log(e);

                if (Swal.isLoading()) {
                    Swal.hideLoading()
                }
            }, 500);

            if (typeof (e) == "object") {
                if (e.code == "ERR_NETWORK") {
                    alert.alertAsync(e.response.data, { icon: "error" });
                }
                else if (e.response == null) {
                    let tmp = document.createElement("DIV");
                    tmp.innerHTML = e.text;

                    alert.errorShowAsync(tmp.innerText);
                }
                else if (e.response.headers["token-expired"] == "true") {
                    if (this.__tokenRefreshCount > 3) {
                        window.$cookies.remove("token");
                        window.$cookies.remove("refreshToken");

                        location.href = "/login";
                        return;
                    }

                    this.__tokenRefreshCount++;
                    isTokenRefresh = true;
                }
                else if (e.response.headers["token-denied"] == "true") {
                    await alert.errorShowAsync("token denied. need relogin ");
                    window.$cookies.remove("token");
                    window.$cookies.remove("refreshToken");

                    location.href = "/login";
                    throw e;
                }
                else if (e.response.data != null) {
                    //console.log(e);
                    alert.errorShowAsync(e.response.data);
                }
                else if (e.message != null) {
                    alert.errorShowAsync(`${e.message}`);
                }
                else {
                    alert.errorShowAsync("unknown axios error");
                }
            }
            else {
                alert.errorShowAsync("undefined axios error");
            }

            if (isTokenRefresh == true) {
                try {
                    await this.postAsync("/api/login/tokenRefresh");
                    this.__tokenRefreshCount = 0;
                }
                catch (ex) {
                    //console.error(ex);
                    await alert.errorShowAsync("token expired. need login ");
                    location.href = "/login";
                    throw e;
                }

                return await this.callAsync(method, url, options);
            }
            else {
                throw e;
            }
        }
    },
    objectToQuerystring: function (url, obj) {
        var str = "";
        var val;

        for (let key in obj) {
            val = obj[key];

            if (typeof (val) === "undefined")
                val = null;

            if (val !== null) {
                if (typeof (val) === "string" && val === "") {
                    continue;
                }
                else if (Object.prototype.toString.call(val) === "[object Date]") {
                    val = util.toLocalDateString(val);
                }
                else if (Array.isArray(val) === true) {
                    val.forEach(item => {
                        str += key + "=" + encodeURIComponent(item) + "&";
                    });

                    continue;
                }
                else if (typeof (val) === "object") {
                    if (val._isBigNumber) { //BigNumber의 경우 추가
                        str += key + "=" + val.toString() + "&";
                    }

                    continue;
                }


                str += key + "=" + encodeURIComponent(val) + "&";
            }
        }

        //마지막 & 삭제
        if (str.length > 0) {
            str = str.substring(0, str.length - 1);
        }

        let paramSperator = "?";
        if (url.indexOf('?') > -1) {
            paramSperator = "&";
        }

        if (str.length < 1) {
            paramSperator = "";
        }

        return `${url}${paramSperator}${str}`;
    },
    setReasonOptions(options, reason) {
        if (typeof (options) == "undefined")
            options = {};

        if (typeof (options.headers) == "undefined")
            options.headers = {};
/*
        if (typeof (reason) != "string")
            throw new Error("reason type is not string");

        if (reason.length < 2)
            throw new Error("reason length < 2");
*/
        options.headers["X-ADMIN-REASON"] = encodeURIComponent(reason);

        return options;
    },
    getAsync: async function (url, options) {
        url = this.objectToQuerystring(url, options);
        return await this.callAsync("get", url, options);
    },
    postReasonAsync: async function (url, reason, data, options) {
        options = this.setReasonOptions(options, reason);

        return await this.postAsync(url, data, options);
    },
    postAsync: async function (url, data, options) {
        if (typeof (options) == "undefined") {
            options = {};
        }
        options.data = data;

        return await this.callAsync("post", url, options);
    },
    putReasonAsync: async function (url, reason, data, options) {
        options = this.setReasonOptions(options, reason);

        return await this.putAsync(url, data, options);
    },
    putAsync: async function (url, data, options) {
        if (typeof (options) == "undefined") {
            options = {};
        }
        options.data = data;

        return await this.callAsync("put", url, options);
    },
    deleteReasonAsync: async function (url, reason, options) {
        options = this.setReasonOptions(options, reason);

        return await this.deleteAsync(url, options);
    },
    deleteAsync: async function (url, options) {
        url = this.objectToQuerystring(url, options);
        return await this.callAsync("delete", url, options);
    },
    multipartAsync: async function (url, data, options) {
        if (typeof (options) == "undefined") {
            options = {};
        }
        options.data = data;

        return this.callAsync("post", url, options);
    },
    downloadAsync: async function (url, options) {
        if (typeof (options) == "undefined") {
            options = {};
        }
        options.responseType = "blob";

        let response = await this.callAsync("get", url, options);
        //console.log(response);
        const blob = new Blob([response.data]);
        // 특정 타입을 정의해야 경우에는 옵션을 사용해 MIME 유형을 정의 할 수 있습니다.
        // const blob = new Blob([this.content], {type: 'text/plain'})

        // blob을 사용해 객체 URL을 생성합니다.
        const fileObjectUrl = window.URL.createObjectURL(blob);

        // blob 객체 URL을 설정할 링크를 만듭니다.
        const link = document.createElement("a");
        link.href = fileObjectUrl;
        link.style.display = "none";

        // 다운로드 파일 이름을 지정 할 수 있습니다.
        // 일반적으로 서버에서 전달해준 파일 이름은 응답 Header의 Content-Disposition에 설정됩니다.
        const disposition = response.headers["content-disposition"];
        const fileName = decodeURI(
            disposition
                .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
                .replace(/['"]/g, "")
        );

        link.download = fileName;

        // 다운로드 파일의 이름은 직접 지정 할 수 있습니다.
        // link.download = "sample-file.xlsx";

        // 링크를 body에 추가하고 강제로 click 이벤트를 발생시켜 파일 다운로드를 실행시킵니다.
        document.body.appendChild(link);
        link.click();
        link.remove();

        // 다운로드가 끝난 리소스(객체 URL)를 해제합니다.
        window.URL.revokeObjectURL(fileObjectUrl);
    },
    hasTableAsync: async function (dbType, tableName, columnName) {
        if (typeof (columnName) == "undefined") {
            let data = await this.getAsync(`/api/server/tableCheck?dbType=${dbType}&tableName=${tableName}`);
            return data.result;
        }

        let data = await this.getAsync(`/api/server/tableCheck?dbType=${dbType}&tableName=${tableName}&columnName=${columnName}`);
        return data.result;
    },
    hasGameTableAsync: async function (tableName, columnName) {
        return await this.hasTableAsync("game", tableName, columnName);
    },
    hasLogTableAsync: async function (tableName, columnName) {
        return await this.hasTableAsync("log", tableName, columnName);
    }
}

export default AjaxHelper

