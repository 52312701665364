<script>
    export default {
        name: "NDataGridColumn",
        props: {
            header: String,
            field: String,
            dataType: String,
            editType: String,
            width: {
                type: Number,
                default: null
            },
            align: String
        }
    }
</script>
